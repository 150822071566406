import React, { useState } from 'react'
//
import Logo from '../../assets/logo.png'
//
import { constants } from '../../constants';
//
import { HiMenu } from "react-icons/hi";
//
import styles from './navbar.module.css';

export default function Navbar() {

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className={styles['container']}>
      <img
        className={styles['logo']}
        src={Logo}
        alt='logo'
      />
      <ul
        className={styles['nav-list']}
        style={!menuOpen 
          ? { maxHeight: '0px' }
          : {}
        }
      >
        <li className={styles['nav-item']}>
          <a href='#pedir-chip'>Início</a>
        </li>
        <li className={styles['nav-item']}>
          <a href={constants.links.linkPedirChip}>Pedir Chip</a>
        </li>
        <li className={styles['nav-item']}>
          <a href={constants.links.linkApp}>Aplicativo</a>
        </li>
        <li className={styles['nav-item']}>
          <a href={constants.links.linkWpp}>Contato</a>
        </li>
        <li className={styles['nav-item']}>
          <a href={constants.links.linkMapaCobertura}>Mapa Cobertura</a>
        </li>

        {/* <li className={styles['nav-item']}>
          <a href={constants.links.linkTenhaSuaOperadora}>Tenha Sua Própria Operadora</a>
        </li> */}
        
      </ul>

      <HiMenu
        className={styles['icon-menu']}
        style={menuOpen
          ? { transform: 'rotateZ(-90deg)' }
          : {}
        }
        onClick={() => { setMenuOpen(!menuOpen) }}
      />

    </header>
  )
}
