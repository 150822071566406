export const constants = {
    
    api:{
        parceiro: 'AIVA',
    },

    texts:{
        nomeEmpresa: 'AIVA',
    },

    links:{
        linkPedirChip: '',
        linkApp: 'https://bit.ly/3cvjegb',
        linkWpp: 'https://api.whatsapp.com/send?phone=+5561994057357&text=Oi!%20Vim%20pelo%20site%2C%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es',
        linkMapaCobertura: 'https://tim.img.com.br/mapa-cobertura/',
        linkTenhaSuaOperadora: 'https://api.whatsapp.com/send?phone=5561920040101&text=quero%20saber%20mais',
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://privacidade.operadora.app.br/#/aiva',
        linkInstagram: 'https://instagram.com/aivatelecom?igshid=YmMyMTA2M2Y=',
    },

}