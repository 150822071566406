import React from 'react'
//
import { OnlinePresentationSVG } from '../../../assets/svg/OnlinePresentationSVG';
//
import { constants } from '../../../constants';
//
import { FaGlasses } from "react-icons/fa";
//
import styles from './banner-sobre-nos.module.css';

export default function BannerSobreNos() {
    return (
        <section id='sobre-nos' className={styles['container']}>

            <div className={styles['left-side']}>
                <div className={styles['subtitle']}>
                    <FaGlasses />
                    <span>Sobre a {constants.texts.nomeEmpresa}</span>
                </div>
                <span className={styles['title']}>
                    Somos diferentes de tudo que você já viu!
                </span>
                <span className={styles['text']}>
                    Uma operadora 100% digital - queremos mudar a forma como
                    você se relaciona com a sua operadora. Sem treta! Direto
                    e objetivo, com atendimento diferenciado, mais humano,
                    simples, prático e eficiente. Usando a tecnologia para
                    o que ela foi criada: facilitar a vida de todos.
                </span>
            </div>
            <div className={styles['right-side']}>
                <OnlinePresentationSVG
                    color={'var(--primaryM)'}
                    style={{ width: '80%' }}
                />
            </div>
        </section>
    );
}
