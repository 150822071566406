import React from 'react'
//
import { FaWhatsapp } from 'react-icons/fa'
//
import styles from './botao-whats.module.css';

export default function BotaoWhats() {
  return (
    <a 
        className={styles['link-whats']}
        href="https://api.whatsapp.com/send?phone=5555933001308&text=Quero%20saber%20da%20INFINITI"
    >
        <FaWhatsapp/>
    </a>
  )
}
