import React from 'react';
//
import Foguete4G from '../../../assets/foguete_4g.png';
import BgLines from '../../../assets/bg_lines.png';
//
import { constants } from '../../../constants';
import { FaWifi, FaSimCard } from "react-icons/fa";
//
import styles from './banner-pedir-chip.module.css';
import Botao from '../../../components/Botao';

export default function BannerPedirChip() {
    return (
        <section id='pedir-chip' className={styles['container']}>

            {/* <img
                className={styles['bg-tr']}
                src={BgLines}
                alt='bg_top_left'
            /> */}

            <div className={styles['left-side']}>

                <div className={styles['txt-100']}>
                    <FaWifi />
                    <span>OPERADORA 100% DIGITAL</span>
                </div>

                <span className={styles['title']}>
                    A ERA DIGITAL CHEGOU, VOCÊ ESTÁ <div style={{ color: 'var(--primaryM)' }}>PRONTO?</div>
                </span>

                <span className={styles['subtitle']}>
                    A {constants.texts.nomeEmpresa} vem revolucionando a sensação de
                    liberdade no coração de cada pessoa e empresa,
                    se livre da burocracia e sem letras miudas.
                </span>

                <Botao
                    type='link'
                    icon={<FaSimCard />}
                    marginLeft={0.5}
                    text='PEDIR CHIP'
                    href={constants.links.linkPedirChip}
                    style={{ marginTop: '1rem' }}
                />

            </div>

            <div className={styles['right-side']}>
                <img
                    className={styles['img-foguete']}
                    src={Foguete4G}
                    alt='foguete_4g'
                />
            </div>

        </section>
    );
}
